import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import {
  addDeposit,
  addForexDeposit,
  paymentGatewayDeposit,
} from "apis/deposit";
import { allowedMethods } from "./Methods/allowedMethods";
import WireTransfer from "./Methods/WireTransfer";
import Others from "./Methods/Others";
import CardWrapper from "components/Common/CardWrapper";
import CustomModal from "components/Common/CustomModal";
import StageTwo from "./StageTwo";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets, getAccountsStart } from "store/actions";
import OlxForex from "./Methods/OlxForex";
import CompanyCrypto from "./Methods/Crypto";
import UPI from "./Methods/UPI";

function Deposit({ isOpen, toggleOpen, type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Selectors
  const { wallets } = useSelector((state) => state?.walletReducer);
  // States
  const [activeStep, setActiveStep] = useState(0);
  const [gateway, setGateway] = useState("");
  const [result, setResult] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  // used to check if amount is less than 15 then raise an error
  const [hoveredPaymentMethod, setHoveredPaymentMethod] = useState();
  const [paymentPayload, setPaymentPayload] = useState({});

  const [isFirstStepValid, setIsFirstStepValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const targetCurrency = "USD";
  const [finitic_PayUrl, setFiniticPayUrl] = useState(null);
  const { clientData } = useSelector((state) => state.Profile);
  const clientEmail = clientData?.email;

  // Effects
  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setGateway("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (type === "mt5Deposit") {
      dispatch(getAccountsStart());
    } else if (!wallets) {
      dispatch(fetchWallets());
    }
  }, [type]);

  // Handlers
  function toggleTab(tab) {
    if (activeStep !== tab) {
      setActiveStep(tab);
    }
  }

  const stageOnePaymentDetails = () => {
    switch (gateway) {
      case "WIRE_TRANSFER":
        return (
          <WireTransfer t={t} setIsFirstStepValid={setIsFirstStepValid}
            setBaseCurrency={setBaseCurrency} setPaymentPayload={setPaymentPayload} />
        );
      case "FINITIC_PAY":
      case "OLX_FOREX":
        return (
          <OlxForex t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "NETELLER":
      case "MASTERCARD":
      case "SKRILL":
        return (
          <Others t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "UPI":
        return (
          <UPI t={t} setIsFirstStepValid={setIsFirstStepValid} setBaseCurrency={setBaseCurrency} setPaymentPayload={setPaymentPayload} />
        );
      case "CRYPTO":
        return (
          <CompanyCrypto t={t} setIsFirstStepValid={setIsFirstStepValid} setPaymentPayload={setPaymentPayload} />
        );
      default:
        return (
          <div className="text-center">
            <h5 className="mb-3">
              {t("Please Select Payment Method")}
            </h5>
          </div>
        );
    }
  };

  const submitHandler = (data) => {
    if (type === "fiatDeposit") {
      if (["OLX_FOREX", "FINITIC_PAY"].includes(selectedPaymentMethod)) {
        paymentGatewayDeposit(data, selectedPaymentMethod).then((res) => {
          setResult(res);
          toggleTab(2);
          if (["FINITIC_PAY"].includes(selectedPaymentMethod)) {
            setFiniticPayUrl(res.result.url);
          }
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
      } else {
        addDeposit(data).then((res) => {
          setLoading(false);
          setResult(res);
          toggleTab(2);
        }).catch((err) => {
          setLoading(false);
          setResult(err);
          toggleTab(2);
        });
      }

    } else if (type === "mt5Deposit") {
      if (["FINITIC_PAY"].includes(selectedPaymentMethod)) {
        paymentGatewayDeposit(data, selectedPaymentMethod).then((res) => {
          setResult(res);
          toggleTab(2);
          if (["FINITIC_PAY"].includes(selectedPaymentMethod)) {
            setFiniticPayUrl(res.result.url);
          }
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
      } else {
        addForexDeposit(data).then((res) => {
          setResult(res);
          toggleTab(2);
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
      }
    }
  };

  const steps = [
    {
      header: t("Select Method"),
      content: (
        <>
          <div>
            <div className="mb-0">
              <h6 className="mb-3">
                {t("Select Payment Method")}
              </h6>
              <Row className="justify-content-center payment-methods">
                {
                  allowedMethods.filter((method) => method.allowed.includes(type)).map((method) => (
                    <Col key={method.gateway} xs={4} lg={2} className="my-2">
                      <button
                        type="button"
                        onClick={() => {
                          setGateway(method.gateway);
                          setSelectedPaymentMethod(method.gateway);
                        }}
                        onMouseEnter={() => { setHoveredPaymentMethod(method.gateway) }}
                        onMouseLeave={() => { setHoveredPaymentMethod() }}
                        className={`btn btn-${selectedPaymentMethod === method.gateway ? "success" : hoveredPaymentMethod === method.gateway ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                        style={{
                          height: "100%",
                          maxHeight: "80px",
                          width: "100%",
                          maxWidth: "80px",
                        }}
                      >
                        <img
                          src={method.image}
                          style={{
                            objectFit: "cover",
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        ></img>
                      </button>
                    </Col>
                  ))
                }
              </Row>
            </div>
            {gateway && stageOnePaymentDetails()}
            {gateway === "WIRE_TRANSFER" &&
              <Row>
                <Col xs={12} className="text-center py-2">
                  <h4>Local Bank Deposit Terms & Conditions</h4>
                </Col>
                <Col xs={12} className="py-2">
                  <div>
                    <p> 1.	No cash deposits accepted.</p>
                    <p> 2.	No third-party payments allowed. Senders name must match your trading account name.</p>
                    <p> 3.	Double-check your account details before making the transaction.</p>
                    <p> 4.	Share a screenshot of the transaction with DATE, REFERENCE NUMBER/TRANSACTION ID, BANK NAME & AMOUNT.</p>
                    <p> 5.	Jupiter Global Markets accepts only Bank transfers in your local currency.</p>
                    <p> 6.	After a successful transaction, funds will be credited to your trading account within 1-4 hours upon receipt by Jupiter Global Markets. Delays may occur due to bank or Jupiter Global Markets verifications. Keep your bank confirmation for reference.</p>
                    <p> 7.	You can withdraw funds deposited via Bank transfer to any bank account under your name.</p>
                    <p> 8.	Withdrawals must return to the original deposit account. Incoming and outgoing funds must match.</p>
                  </div>
                </Col>
              </Row>}
            {gateway === "UPI" && <Row>
              <Col xs={12} className="text-center py-2">
                <h4>UPI Deposit Terms & Conditions</h4>
              </Col>
              <Col xs={12} className="py-2">
                <div>
                  <p>1. Jupiter Global Markets accepts only UPI Payment in Indian Rupees (INR).</p>
                  <p>2. No third-party payments allowed. Senders name must match your trading account name.</p>
                  <p>3. Share a screenshot of the transaction with DATE, REFERENCE NUMBER/TRANSACTION ID, BANK NAME & AMOUNT.</p>
                  <p>4. After a successful transaction, funds will be credited to your trading account and wallet within 1-4 hours upon receipt by Jupiter Global Markets. Delays may occur due to bank or Jupiter Global Markets verifications. Keep your bank confirmation for reference.</p>
                  <p>5. You can withdraw funds deposited via Bank transfer to any bank account under your name.</p>
                </div>
              </Col>
            </Row>}
            {gateway === "CRYPTO" && <Row>
              <Col xs={12} className="text-center py-2">
                <h4>Crypto Deposit Terms & Conditions</h4>
              </Col>
              <Col xs={12} className="py-2">
                <div>
                  <p>1. Clients can deposit a minimum of 10 USDT-TRC20.</p>
                  <p>2. Deposit Depending on the Blockchain.</p>
                  <p>3. Withdrawal will be allowed in the same mode as deposit is made.</p>
                  <p>4. This is the TRC20 Tether address. Any deposit sent by mistake on another blockchain or to an incompatible address may result in the loss of funds forever.</p>
                  <p>5. At the time of withdrawal, it&apos;s a sole responsibility of the client while providing the Wallet ID.</p>
                </div>
              </Col>
            </Row>}
            <div className="text-center mt-4">
              <Button
                type="submit"
                className="btn btn-success waves-effect waves-light w-lg btn-sm"
                disabled={((gateway === "") || !isFirstStepValid)}
                onClick={() => toggleTab(1)}
              >
                {t("Continue")}
              </Button>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Enter Amount",
      content: (
        <StageTwo
          t={t}
          setLoading={setLoading}
          loading={loading}
          toggleTab={toggleTab}
          type={type}
          gateway={gateway}
          handleSubmit={submitHandler}
          paymentPayload={paymentPayload}
          receipt={allowedMethods.find((method) => method.gateway === gateway)?.receipt}
          targetCurrency={targetCurrency}
          baseCurrency={baseCurrency}
        />
      ),
    },
    {
      header: "Deposit status",
      content: (
        <>
          {
            gateway === "FINITIC_PAY" ? (
              <div>
                {t("Please complete payment")}
                <div className="mt-4">
                  <iframe
                    src={finitic_PayUrl}
                    title="Finitic Pay"
                    style={{
                      width: "100%",
                      height: "70vh",
                    }}
                  />
                </div>
              </div>
            ) : (
              <>
                {result.status ? (
                  <>
                    <div className="text-center  mb-4">
                      <h1 className="fs-1 mb-5">
                        {t("Yay!")} <i className="bx bx-wink-smile"></i>
                      </h1>
                      <p>{t("Pending Deposit Thank You")}</p>
                      <p>
                        <span className="positive">
                          {
                            type === "fiatDeposit" ? result?.result?.amount?.$numberDecimal : result?.result?.amount
                          }
                          {
                            " "
                          }
                          {
                            type === "fiatDeposit" ? result?.result?.currency : result?.result?.currency
                          }
                        </span>
                      </p>
                      <span className="text-muted">
                        {t("Your transaction ID is")}
                        {result?.result?._id}
                      </span>
                    </div>
                    <CardWrapper className="mb-4">
                      <div className="d-flex align-items-center justify-content-around px-4">
                        <div>
                          <div className="text-muted">{t("Status")}</div>
                          <div className="positive">{t(result?.result?.status)}</div>
                        </div>
                        <div>
                          <div className="text-muted">{t("GATEWAY")}</div>
                          <div>{result.result?.gateway}</div>
                        </div>
                      </div>
                    </CardWrapper>
                  </>
                ) : (
                  <>
                    <div className="text-center  mb-4">
                      <h1 className="fs-1 mb-5">
                        {t("Oops!")} <i className="bx sad"></i>
                      </h1>
                      <p>{t("Your Deposit Request Not Successfully Created")}</p>
                    </div>
                    <CardWrapper className="mb-4">
                      <div className="d-flex align-items-center justify-content-between px-5">
                        <div>
                          <div className="positive">{result.message}</div>
                        </div>
                      </div>
                    </CardWrapper>
                    
                    <div className="text-center">
                      <Button
                        className="btn btn-danger m-2 btn-sm w-lg"
                        onClick={toggleOpen}
                      >
                        {t("Continue")}
                      </Button>
                    </div>
                  </>
                )}
              </>
            )
          }
        </>
      ),
    },
  ];
  return (
    <>
      <CustomModal
        steps={steps}
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        activeStep={activeStep}
        toggleTab={toggleTab}
      ></CustomModal>
    </>

  );
}
export default withTranslation()(Deposit); 
