import { AvField, AvForm } from "availity-reactstrap-validation";
import calculateFee from "helpers/calculateFee";
import { validateFile } from "helpers/validations/file";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { 
  Button, InputGroup, InputGroupText, Label 
} from "reactstrap";
import FiatFormDetails from "./Fiat/FiatFormDetails";
import Mt5Details from "./MT5/Mt5Details";
import { getConversionRate } from "apis/conversionRate";
import { getFiniticPayFeesConfig } from "apis/deposit";

export default function StageTwo(props) {
  const {
    t,
    toggleTab,
    type,
    gateway,
    handleSubmit,
    receipt = false,
    loading,
    paymentPayload,
    setLoading,
    targetCurrency,
    baseCurrency,
  } = props;
  const [file, setFile] = useState({});
  const [amount, setAmount] = useState();
  const [amountValidation, setAmountValidation] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(undefined);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [denyMinDeposit, setDenyMinDeposit] = useState(false);
  const tranFeeGroupDetails = useSelector(
    (state) => state.Profile?.clientData?.transactionFeeId
  );
  const [transactionFee, setTransactionFee] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [refId, setRefId] = useState(0);
  const [minimumDepositAmount, setMinimumDepositAmount] = useState(15);
  const [feesLoading, setFeesLoading] = useState(false);
  const [fees, setFees] = useState({});

  const amountValidationHandler = () => {
    amount < 10 ? setAmountValidation(true) : setAmountValidation(false);
  };
  const accMinDepositHandler = () => {
    amount < selectedAccount?.accountTypeId.minDeposit ? setDenyMinDeposit(true) : setDenyMinDeposit(false);
  };

  useEffect(() => {
    if (gateway === "FINITIC_PAY") {
      setFeesLoading(true);
      getFiniticPayFeesConfig()
        .then((resp) => resp.result)
        .then((res) => setFees(res))
        .finally(() => setFeesLoading(false));
    }
  }, [gateway]);

  useEffect(() => {
    if (gateway !== "FINITIC_PAY") {
      setMinimumDepositAmount(15);
    }
  }, [gateway]);

  useEffect(() => {
    if (amount > 0) {
      if (gateway === "FINITIC_PAY") {
        const { isPercentage, minDeposit, onChainfee, value, minValue } = fees;
        if (isPercentage) {
          const cFees = parseFloat((value * amount) / 100);
          const feeAmount = cFees <= parseFloat(minValue) ? parseFloat(minValue) : cFees;
          setTransactionFee(parseFloat(feeAmount).toFixed(2));
          const maxMinimumDepositAmount = minDeposit > selectedAccount?.accountTypeId?.minDeposit ? selectedAccount?.accountTypeId?.minDeposit : minDeposit;
          setMinimumDepositAmount(parseFloat(maxMinimumDepositAmount));
          console.log("settings", feeAmount, minDeposit);
        } else {
          setTransactionFee(parseFloat(onChainfee + minValue).toFixed(2));
        }
      } else {
        setTransactionFee(tranFeeGroupDetails ? calculateFee(tranFeeGroupDetails, amount) : 0);
      }
      amountValidationHandler();
      accMinDepositHandler();
    } else {
      setTransactionFee(0);
    }
  }, [tranFeeGroupDetails, amount, gateway, selectedAccount]);


  useEffect(() => {
    if (
      type === "fiatDeposit" &&
      selectedWallet &&
      selectedWallet?.symbol !== targetCurrency
    ) {
      getConversionRate({
        baseCurrency: selectedWallet?.label,
        targetCurrency: baseCurrency,
      }).then((res) => {
        setConversionRate(res);
      });
    } else {
      getConversionRate({
        baseCurrency: "USD",
        targetCurrency: baseCurrency,
      }).then((res) => {
        setConversionRate(res);
      });
    }
  }, [baseCurrency, targetCurrency, selectedWallet]);

  const renderFormType = () => {
    switch (type) {
      case "fiatDeposit":
        return (
          <FiatFormDetails
            t={t}
            selectedWallet={selectedWallet}
            amount={amount}
            setAmount={setAmount}
            setAmountValidation={setAmountValidation}
            setSelectedWallet={setSelectedWallet}
            baseCurrency={baseCurrency}
            conversionRate={conversionRate}
            minDepositAmount={minimumDepositAmount}
          />
        );
      case "mt5Deposit":
        return (
          <Mt5Details
            t={t}
            amount={amount}
            setAmount={setAmount}
            denyMinDeposit={denyMinDeposit}
            baseCurrency={baseCurrency}
            conversionRate={conversionRate}
            setSelectedAccount={setSelectedAccount}
            selectedAccount={selectedAccount}
            minDepositAmount={minimumDepositAmount}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <AvForm
      onValidSubmit={(e, v) => {
        setLoading(true);
        const formData = new FormData();
        if (type === "mt5Deposit") {
          if (receipt) {
            formData.append("gateway", gateway);
            formData.append("amount", parseFloat(amount));
            formData.append("note", "note");
            formData.append("tradingAccountId", v.tradingAccountId);
            formData.append("receipt", file);
            {
              gateway === "WIRE_TRANSFER" && formData.append("refId", refId);
            }
            formData.append("paymentPayload", JSON.stringify(paymentPayload));
            handleSubmit(formData);
          } else {
            handleSubmit({
              gateway,
              paymentPayload: JSON.stringify(paymentPayload),
              amount: parseFloat(amount),
              note: "note",
              tradingAccountId: v.tradingAccountId,
            });
          }
        } else if (type === "fiatDeposit") {
          if (receipt) {
            formData.append("gateway", gateway);
            formData.append("amount", parseFloat(amount));
            formData.append("note", "note");
            formData.append("walletId", selectedWallet.value);
            formData.append("receipt", file);
            {
              gateway === "WIRE_TRANSFER" && formData.append("refId", refId);
            }
            formData.append("paymentPayload", JSON.stringify(paymentPayload));
            handleSubmit(formData);
          } else {
            handleSubmit({
              gateway,
              paymentPayload: JSON.stringify(paymentPayload),
              amount: parseFloat(amount),
              note: "note",
              walletId: selectedWallet.value,
            });
          }
        }
      }}
    >
      <h6 className="mb-3">{t("Enter Amount")}</h6>
      <div className="d-flex justify-content-between mb-2">
        {renderFormType()}
      </div>
      <div className="mb-3">
        <Label className="form-label mb-2">{t("Transaction Fee")}</Label>
        <InputGroup className="">
          <InputGroupText className=" w-100">
            {parseFloat(transactionFee ?? 0)}
            {"   "}
            {selectedWallet?.asset}
          </InputGroupText>
        </InputGroup>
      </div>
      {gateway === "WIRE_TRANSFER" && (
        <div className="mb-3">
          <Label className="form-label" htmlFor="refId">
            {t("Receipt Id")}
          </Label>
          <AvField
            type="text"
            name="refId"
            className="form-control form-control-md"
            errorMessage={t("Receipt Id is required")}
            onChange={(e) => {
              setRefId(e.target.value);
            }}
            validate={{
              required: { value: true },
            }}
          />
        </div>
      )}

      {receipt && (
        <div className="mb-3">
          <Label className="form-label" htmlFor="receipt">
            {t("Receipt")}
          </Label>
          <AvField
            type="file"
            name="receipt"
            className="form-control form-control-md"
            errorMessage={t("Receipt is required")}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
            validate={{
              required: { value: true },
              validate: validateFile(["jpg", "jpeg", "png"], 1000000, file, {
                sizeValidationMessage: t("The file size is too large"),
                extensionValidationMessage: t(
                  "The file extension is not allowed"
                ),
              }),
            }}
          />
        </div>
      )}
      <div className="my-4 text-center">
        <Button
          className="btn btn-primary m-2 btn-sm w-lg"
          onClick={() => toggleTab(0)}
        >
          {t("Back")}
        </Button>
        <Button
          className="btn btn-success m-2 btn-sm w-lg"
          type="submit"
          disabled={amountValidation || loading || denyMinDeposit || !amount}
        >
          {t("Continue")}
        </Button>
      </div>
    </AvForm>
  );
}
