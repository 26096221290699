import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";

import {
  Row, Col, Alert, Container, Spinner, Card
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//Social Media Imports
// import TwitterLogin from "react-twitter-auth"

// actions
import { loginUser, toggleCurrentModal } from "../../store/actions";

// import images
import * as content from "content";

//Import config
import { withTranslation } from "react-i18next";
import TwoFactorAuth from "components/TwoFactorAuth";
import LanguageDropdown from "components/CommonForBoth/TopbarDropdown/LanguageDropdown";

const Login = props => {

  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const dispatch = useDispatch();
  const {  currentModal, modalData } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData
  }));
  const { error, loading, layoutMode } = useSelector(state => ({
    loading: state.Login.loading,
    error: state.Login.error,
    layoutMode: state.Layout.layoutMode,
  }));

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history));
  };


  //handleGoogleLoginResponse

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse

  return (
    <React.Fragment>
      <MetaTags>
        <title>{props.t("Login")}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="mx-auto card-shadow" style={{ margin: "5rem auto" }}>
              <div style={{
                position: "absolute",
                display: "block",
                top: -20,
                right: 0,
                zIndex: 999,
              }}>
                <LanguageDropdown />
              </div>
              <Card className="d-flex p-sm-5 p-4" style={{ marginBottom: "0px" }}>
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-1 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={content.mainLogo} alt="" height="64" />
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      {/* <div className="text-center">
                        <h5 className="mb-0">{props.t("Sign in to continue")}  </h5>
                      </div> */}
                      <AvForm
                        className="custom-form mt-4 pt-2"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v);
                        }}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label={props.t("Email")}
                            className="form-control"
                            placeholder={props.t("Enter email")}
                            type="email"
                            errorMessage={props.t("Enter valid email")}
                            validate={{
                              required: {
                                value: true,
                              },
                              email:{
                                value:true,
                              }
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">{props.t("Password")}</label>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="">
                                <Link to="/forgot-password" className="text-muted">{props.t("Forgot password?")}</Link>
                              </div>
                            </div>
                          </div>

                          <div className="mb-3">
                            <Row>
                              <Col xs={11} 
                                style={{
                                  paddingRight: "0px",
                                }}>
                                <AvField
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  className="form-control"
                                  placeholder={props.t("Enter Password")}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: props.t("Enter valid password")
                                    }
                                  }}
                                  // For disabling pasting
                                  // onPaste={(e) => {
                                  //   e.preventDefault();
                                  // }}
                                />
                              </Col>
                              <Col xs={1}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderTopRightRadius: "0.25rem",
                                  borderBottomRightRadius: "0.25rem",
                                  boxShadow: " 0 1px 2px 0 rgb(0 0 0 / 0.05)"
                                }}
                              >
                                <button className="btn" type="button" onClick={()=>{ setShowPassword(!showPassword) }}>
                                  <i className="mdi mdi-eye-outline"></i>
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="row mb-4">
                          {/* <div className="col">
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" id="remember-check" />
                              <label className="form-check-label" htmlFor="remember-check">
                                {props.t("Remember me")}
                              </label>
                            </div>
                          </div> */}

                        </div>
                        <div className="mt-1 mb-5">
                          <p style={{
                            marginTop:"15px",
                            fontSize: "12px" 
                          }}>
                                  Risk Warning: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. Retail investor accounts lose money when trading CFDs with Jupiter Global Markets. These products thus are not appropriate for all investors. Under no circumstances shall Jupiter Global Markets have any liability to any person or entity for any loss or damage in whole or part caused by, resulting from, or relating to any investing activity.
                          </p>
                        </div>
                        <div className="mb-3">
                          {loading ? <div className="text-center"><Spinner className="ms-2" color="primary" /></div> : <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">Log In</button>}
                        </div>
                        {error && error ? (
                          <Alert color="danger"> {props.t(error)}</Alert>
                        ) : null}
                      </AvForm>
                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>

                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => { }}
                            />
                          </li>
                        </ul>
                      </div> */}

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          {props.t("Don't have an account ?")} 
                          <Link to="/register/forex/live" className="text-primary fw-semibold"> 
                            {props.t("Signup now")} 
                          </Link> 
                        </p>
                      </div>
                    </div>
                    {/* <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()} Minia . Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                    </div> */}
                  </div>
                </div>
              </Card>
            </Col>
            
          </Row>
        </Container>
        {modalData && currentModal === "TwoFactorAuth" &&
          <TwoFactorAuth
            isOpen={currentModal === "TwoFactorAuth"}
            email={modalData.email}
            type={modalData.type}
            history={props.history}
            toggleOpen={() => {
              dispatch(toggleCurrentModal(""));
            }}>
          </TwoFactorAuth>}
      </div>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(Login));

Login.propTypes = {
  history: PropTypes.object,
};
